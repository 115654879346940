import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from '../../styles/purchaseOrders.module.css';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import Title from '../../components/Admins/PurchaseOrders/View/Title';
import Top from '../../components/Admins/PurchaseOrders/View/Top';
import Summary from '../../components/Admins/PurchaseOrders/View/Summary';
import * as Button from '../../components/UI/Forms/Button';
import Text from '../../components/UI/Typography/Text';
import GridItem from '../../components/Admins/PurchaseOrders/View/GridItem';
import ReceivingTab from '../../components/Admins/PurchaseOrders/Tabs/ReceivingTab';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import PaymentsTab from '../../components/Admins/PurchaseOrders/Tabs/PaymentsTab';
import InvoicesTab from '../../components/Admins/PurchaseOrders/Tabs/InvoicesTab';
import {Controller, useForm, useWatch} from 'react-hook-form';
import Toast from '../../components/UI/General/Toast';
import spmsServiceService from '../../services/spmsService.service';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import HistoryTab from '../../components/Admins/PurchaseOrders/Tabs/HistoryTab';
import Textarea from '../../components/UI/Forms/Textarea';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import {useAccessAllowed} from '../../hooks/useAccessAllowed';
import ModalReceivedItems from '../../components/Admins/PurchaseOrders/Modals/ModalReceivedItems';
import ModalLoadPayment from '../../components/Admins/PurchaseOrders/Modals/ModalLoadPayment';
import StatusBar from '../../components/Admins/PurchaseOrders/View/StatusBar';
import TabsSlider from '../../components/UI/General/TabsSlider';
import {useStore} from "../../store/store";
import {regExps} from "../../utils/regExps";

const Order = () => {
  const {orderId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useStore((state) => state.user);
  const activeCompany = useStore((state) => state.activeCompany);
  const [tab, setTab] = useState('notes');
  const [values, setValues] = useState(null);
  const [receivedItemsModal, setReceivedItemsModal] = useState(false);
  const [loadPaymentModal, setLoadPaymentModal] = useState(false);
  const [fetchReceiving, setFetchReceiving] = useState(false);
  const [fetchPayments, setFetchPayments] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(0)
  const [currentReceivedItem, setCurrentReceivedItem] = useState(null)
  const {
    handleSubmit,
    control,
    setError,
    formState: {errors, isSubmitSuccessful},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      requisitionId: 0,
      purchaseOrderState: '',
      title: '',
      requester: '',
      notes: [],
      companyId: '',
      branchId: '',
      departmentId: '',
      requestOnBehalfOf: '',
      vendorId: '',
      currency: '',
      approver: '',
      purchaseOrderItems: [],
      purchaseOrderAttachments: [],
      deliveryDate: '',
      deliveryAddress: {},
      purchaseOrderNotes: '',
      vendorNote: '',
    },
    values,
  });
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  let isApprover = useRef(0);
  let isCurrentUserApproved = useRef(false)

  const access = useAccessAllowed(['Purchase_Order', 'Payments', 'Receiving', 'Invoicing']);
  const notesWatcher = useWatch({name:"purchaseOrderNotes", control})

  const subtotal = useMemo(() => {
    return values?.purchaseOrderItems ? values?.purchaseOrderItems.reduce((acc, current) => acc + (parseFloat(current.subtotal) || 0), 0) : 0;
  }, [values]);
  const taxTotal = useMemo(() => {
    return values?.purchaseOrderItems ? values?.purchaseOrderItems.reduce((acc, current) => acc + (parseFloat(current.taxTotal) || 0), 0) : 0;
  }, [values]);
  const totalCost = useMemo(() => {
    return values?.purchaseOrderItems ? values?.purchaseOrderItems.reduce((acc, current) => acc + (parseFloat(current.totalCost) || 0), 0) : 0;
  }, [values]);

  const discard = () => {
    let to;
    switch (true) {
      case location?.state?.from === 'posp':
        to = '/purchase-orders/overview/pending'
        break;
      case location?.state?.from === 'posa':
        to = '/purchase-orders/overview/approved'
        break;
      case location?.state?.from === 'poc':
        to = '/purchase-orders/overview/closed'
        break;
      case location?.state?.from === 'rnf':
        to = '/receiving/overview/not_fulfilled'
        break;
      case location?.state?.from === 'rpf':
        to = '/receiving/overview/partially_fulfilled'
        break;
      case location?.state?.from === 'rf':
        to = '/receiving/overview/fulfilled'
        break;
      case location?.state?.from === 'io':
        to = '/invoices/overview/outstanding'
        break;
      case location?.state?.from === 'ip':
        to = '/invoices/overview/pending_approval'
        break;
      case location?.state?.from === 'ia':
        to = '/invoices/overview/approved'
        break;
      case location?.state?.from === 'ir':
        to = '/invoices/overview/rejected'
        break;
      case location?.state?.from === 'po':
        to = '/payments/overview/outstanding'
        break;
      case location?.state?.from === 'pp':
        to = '/payments/overview/partially_paid'
        break;
      case location?.state?.from === 'pf':
        to = '/payments/overview/fully_paid'
        break;
      case location?.state?.from === 'pc':
        to = '/payments/overview/closed'
        break;
      default:
        to = '/purchase-orders/overview/approved';
    }
    navigate(to)
  }

  const onSubmit = (data, type) => {
    setToast((item) => ({...item, opened: false}));
    console.log(data);
    console.log(type);
    const newData = {
      notes: data.purchaseOrderNotes,
      approved: type === 'approved' ? true : type === 'rejected' ? false : undefined,
      approvedPurchaseOrderItems: data.purchaseOrderItems.map((el) => el.id),
    };
    if (type === 'rejected' && data.purchaseOrderNotes === '') {
      setTab('notes');
      setError('purchaseOrderNotes', {
        type: 'focus',
        message: 'Please give a reason for rejecting the purchase order',
      });
    } else {
      const pac = data.approval.approvers.filter(item => item.status === "PENDING_APPROVAL" && item.userId !== user.id).length
      console.log(pac)
      spmsServiceService
        .approvePurchaseOrder(orderId, newData)
        .then((r) => {
          console.log(r);
          const to = type === 'approved' && pac > 0 ? "pending" : type
          setToast({
            opened: true,
            message: 'Purchase Order successfully ' + type,
            type: 'success',
            cb: () => navigate('/purchase-orders/overview/' + to),
          });
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    }
  };

  const onSubmitNotes = (data) => {
    setToast((item) => ({...item, opened: false}));
    const noteText = data.purchaseOrderNotes.toString()
    spmsServiceService.addNoteToPO(orderId, noteText)
      .then((r) => {
        setTriggerUpdate(s => s+1)
        setToast({
          opened: true,
          message: 'Your Note has been added successfully',
          type: 'success',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      return await spmsServiceService.getPurchaseOrder(orderId);
    };
    if (orderId) {
      fetchData().then((r) => {
        if (r.data.message === 'Operation Successful') {
          isApprover.current = r.data.data.approval?.approvers?.filter(item => item.userId === user.id).length ?? 0;
          isCurrentUserApproved.current = r.data.data.approval?.approvers?.find(item => item.userId === user.id)?.status === "APPROVED" ?? false;
          const {vendorNotes, ...other} = r.data.data
          const newData = {
            ...other,
            vendorNote: vendorNotes?.note
          }
          setValues(newData);
        }
      });
    }
  }, [orderId, triggerUpdate, user]);

  useEffect(() => {
    if (location.state === null) return
    if (location.state.tabToSwitch !== undefined){
      let timer1 = setTimeout(() => setTab(location.state.tabToSwitch), 600);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [location.state])

  const varianceCalc = () => values?.totalInvoicedAmount > totalCost ? values?.totalInvoicedAmount - totalCost : 0

  const currencyOptionsPayments =  useCallback(() => {
    if(activeCompany?.defaultCurrency === values?.currency?.code){
      return [{label: activeCompany?.defaultCurrency, value: activeCompany?.defaultCurrency}]
    }else {
      return [
        {label: activeCompany?.defaultCurrency, value: activeCompany?.defaultCurrency},
        {label: values?.currency?.code, value: values?.currency?.code}
      ]
    }
  },[activeCompany?.defaultCurrency, values?.currency?.code])
  console.log(access)
  return (
    <>
      <Title
        title={values?.title}
        poNumber={values?.purchaseOrderState === 'APPROVED' ? values?.purchaseOrderNo : null}
        poState={values?.purchaseOrderState}
      />
      <Box $mobExtend $asHolder>
        {values?.purchaseOrderState === 'APPROVED' && <StatusBar data={values}/>}

        <Top data={values}/>

        <form id="poForm">
          <div className={styles.table}>
            <div className={styles.tableHead}>
              <div/>
              <Text type="body-1" weight={500}>
                Description
              </Text>
              <Text type="body-1" weight={500}>
                GL Code
              </Text>
              <Text type="body-1" weight={500}>
                Quantity
              </Text>
              <Text type="body-1" weight={500}>
                Unit Price
              </Text>
              <Text type="body-1" weight={500}>
                Tax
              </Text>
              <Text type="body-1" weight={500}>
                Subtotal
              </Text>
            </div>
            <div className={styles.tableBody}>
              {values?.purchaseOrderItems.map((item) => (
                <GridItem
                  key={item.id}
                  data={item}
                  id={item.id}
                  budgetCur={values?.budget?.currency}
                  exchangeRate={values?.currency?.rate !== undefined ? values?.currency?.rate : 1}
                />
              ))}
            </div>
          </div>
          <div className={styles.summaryWrapper}>
            <div className={styles.vendorNotes}>
              <Text type={"body-1"} weight={600}>Vendor Notes</Text>
              <Text type={"body-1"}>{values?.vendorNote ?? "There're no Vendor Notes"}</Text>
            </div>
            <Summary
              subtotal={subtotal}
              taxTotal={taxTotal}
              totalCost={totalCost}
              currency={values?.currency?.code}
              currencyBudget={values?.budget?.currency}
              totalBudgetCost={values?.purchaseOrderState === 'APPROVED' ? undefined : values?.currency?.rate * totalCost}
              // totalInvoicedAmount={values?.purchaseOrderState === 'APPROVED' ? values?.totalInvoicedAmount : undefined}
              variance={values?.purchaseOrderState === 'APPROVED' ? varianceCalc() : undefined}
            />
          </div>
        </form>

        <TabsSlider className={generalStyles.tabs} selected={tab}>
          <span onClick={() => setTab('notes')}>Notes</span>
          <span onClick={() => setTab('documents')}>Documents</span>
          <span onClick={() => setTab('history')}>History</span>
          <span onClick={() => setTab('approval')}>Approval</span>
          {((values?.purchaseOrderState === 'APPROVED' || values?.purchaseOrderState === 'CLOSED') && (access?.receiving?.viewOwn || access?.receiving?.viewAll || access?.receiving?.create)) && (
            <span onClick={() => setTab('receiving')}>Receiving</span>
          )}
          {((values?.purchaseOrderState === 'APPROVED' || values?.purchaseOrderState === 'CLOSED') && (access?.invoicing?.viewOwn || access?.invoicing?.viewAll || access?.invoicing?.create)) && (
            <span onClick={() => setTab('invoices')}>Invoices</span>
          )}
          {((values?.purchaseOrderState === 'APPROVED' || values?.purchaseOrderState === 'CLOSED') && (access?.payments?.viewOwn || access?.payments?.viewAll || access?.payments?.create)) && (
            <span onClick={() => setTab('payments')}>Payments</span>
          )}
        </TabsSlider>

        <div className={styles.attachmentsBlock}>
          {tab === 'notes' && (
            <div className={styles.attachmentsBlockItem}>
              {values?.purchaseOrderState !== 'CLOSED' ? (
                <div className="inp-container">
                  <Controller
                    name="purchaseOrderNotes"
                    control={control}
                    rules={{
                      pattern: {
                        value: regExps.notes,
                        message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £"
                      },
                    }}
                    render={({field}) => (
                      <Textarea
                        {...field}
                        $low
                        $counter
                        $counterMax={500}
                        placeholder="Notes text goes here"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                      />
                    )}
                  />
                  {errors.purchaseOrderNotes && <p className="error-message">{errors.purchaseOrderNotes?.message}</p>}
                </div>
              ) : null}
              <NotesTab data={values?.notes}/>
            </div>
          )}
          {tab === 'documents' && <QuotesTab data={values?.attachments}/>}
          {tab === 'history' && <HistoryTab data={values?.history}/>}
          {tab === 'approval' && <ApprovalTab data={values?.approval?.approvers}/>}
          {(values?.purchaseOrderState === 'APPROVED' || values?.purchaseOrderState === 'CLOSED') && (
            <>
              {(tab === 'receiving' && (access?.receiving?.viewOwn || access?.receiving?.viewAll || access?.receiving?.create)) && (
                <ReceivingTab
                  refetch={fetchReceiving}
                  shouldRefetch={() => setFetchReceiving(false)}
                  setCurrentReceivedItem={setCurrentReceivedItem}
                  setReceivedItemsModal={setReceivedItemsModal}
                  $trigger={setTriggerUpdate}
                  fetchReceiving={() => setFetchReceiving(true)}
                  setToast={setToast}
                  prevLoc={location?.state?.from}
                  viewOnly={values?.purchaseOrderState === 'CLOSED'}
                />
              )}
              {(tab === 'invoices' && (access?.invoicing?.viewOwn || access?.invoicing?.viewAll || access?.invoicing?.create)) && <InvoicesTab setToast={setToast} $trigger={setTriggerUpdate} prevLoc={location?.state?.from}/>}
              {(tab === 'payments' && (access?.payments?.viewOwn || access?.payments?.viewAll || access?.payments?.create)) && (
                <PaymentsTab
                  refetch={fetchPayments}
                  shouldRefetch={() => setFetchPayments(false)}
                  $trigger={setTriggerUpdate}
                  setToast={setToast}
                  prevLoc={location?.state?.from}
                  viewOnly={values?.purchaseOrderState === 'CLOSED'}
                />
              )}
            </>
          )}
        </div>
      </Box>
      <div className={generalStyles.pageButtons}>
        {(values?.purchaseOrderState === 'PENDING_APPROVAL' && access?.purchase_order?.approve && isApprover.current > 0 && !isCurrentUserApproved.current) && (
          <>
            <Button.Main
              $primary
              $style="pink"
              type="submit"
              id="poForm"
              disabled={!values?.purchaseOrderItems?.length || isSubmitSuccessful}
              onClick={handleSubmit((data) => onSubmit(data, 'approved'))}
            >
              Approve
            </Button.Main>
            <Button.Main
              $primary
              $style="pink"
              type="submit"
              id="poForm"
              disabled={isSubmitSuccessful && !errors.length}
              onClick={handleSubmit((data) => onSubmit(data, 'rejected'))}
            >
              Reject
            </Button.Main>
          </>
        )}
        {values?.purchaseOrderState === 'APPROVED' && (
          <>
            <Button.Main
              $primary
              $style="pink"
              type="submit"
              id="poForm"
              disabled={notesWatcher === undefined || notesWatcher === ""}
              onClick={handleSubmit((data) => onSubmitNotes(data))}
            >
              Save Notes
            </Button.Main>
            {(values?.fulfillmentStatus !== 'FULFILLED' && access?.receiving?.create) && (
              <Button.Main $primary $style="pink" onClick={() => setReceivedItemsModal(true)}>
                Receive Items
              </Button.Main>
            )}
            {access?.invoicing?.create && (
              <Button.Main
                $primary
                $style="pink"
                onClick={() => navigate('/purchase-orders/' + orderId + '/invoice')}
              >
                Load Invoice
              </Button.Main>
            )}
            {(values?.paymentStatus !== 'PAID' && access?.payments?.create) && (
              <Button.Main $primary $style="pink" onClick={() => setLoadPaymentModal(true)}>
                Load Payment
              </Button.Main>
            )}
          </>
        )}
        <Button.Main
          $primary
          $style="gray"
          onClick={() => discard()}
          type="button"
        >
          Discard
        </Button.Main>
      </div>

      {toast.opened === true ?
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb}/> : null}
      {receivedItemsModal && (
        <ModalReceivedItems
          $show={receivedItemsModal}
          $trigger={setTriggerUpdate}
          $close={() => (setReceivedItemsModal(false), setCurrentReceivedItem(undefined))}
          $callback={() => (setTab('receiving'), setFetchReceiving(true))}
          poNumber={values?.purchaseOrderNo}
          items={currentReceivedItem ?? values?.purchaseOrderItems}
        />
      )}
      {loadPaymentModal && (
        <ModalLoadPayment
          $show={loadPaymentModal}
          $trigger={setTriggerUpdate}
          $close={() => setLoadPaymentModal(false)}
          $callback={() => (setTab('payments'), setFetchPayments(true))}
          poNumber={values?.purchaseOrderNo}
          currencyOptions={currencyOptionsPayments()}
        />
      )}
    </>
  );
};

export default Order;
