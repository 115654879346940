import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '../../components/UI/General/Box';
import Text from '../../components/UI/Typography/Text';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/budgets.module.css';
import * as Button from '../../components/UI/Forms/Button';
import budgetServiceService from '../../services/budgetService.service';
import BudgetDetailsGlList from './BudgetDetailsGlList';
import Select from 'react-select';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import TabsSlider from '../../components/UI/General/TabsSlider';
import HistoryTab from '../../components/Admins/PurchaseOrders/Tabs/HistoryTab';
import PieChartComponent from '../../components/UI/General/PieChartComponent';
import spmsServiceService from "../../services/spmsService.service";

const BudgetDetails = () => {
  const { companyId, budgetId } = useParams();
  const [glItems, setGlItems] = useState([]);
  const [mainBudget, setMainBudget] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);
  const [budget, setBudget] = useState(null);
  const navigate = useNavigate();
  const [tab, setTab] = useState('notes');

  //Corien budget calculations version
  const [chartData, setChartData] = useState({ pieChart: [], totalAmount: null });
  useEffect(() => {
    const getPieChartData = async () => {
      let result;
      result = await spmsServiceService.getPurchaseOrders(10, 0, {
        companyId: companyId,
        purchaseOrderState: 'APPROVED',
      });
      const totalElements = result.data.data.totalElements;
      const contentLength = result.data.data.content.length;
      if (totalElements > contentLength) {
        result = await spmsServiceService.getPurchaseOrders(totalElements, 0, {
          companyId: companyId,
        });
      }
      const pos = result.data.data.content.filter(el => el?.budget?.id === budgetId);
      console.log(pos)
      const paidPos = pos.filter((po) => ['PARTIALLY_PAID', 'PAID'].includes(po.paymentStatus));
      const unpaidPos = pos.filter((po) => !['PARTIALLY_PAID', 'PAID'].includes(po.paymentStatus));
      const paidPosSum = paidPos.reduce((acc, current) => acc + current.total, 0);
      const unpaidPosSum = unpaidPos.reduce((acc, current) => acc + current.total, 0);
      setChartData({
        pieChart: [
          { name: 'Allocated Unpaid', value: parseFloat(unpaidPosSum.toFixed(2)) },
          { name: 'Allocated Paid', value: parseFloat(paidPosSum.toFixed(2)) },
        ],
        totalAmount: parseFloat(paidPosSum.toFixed(2)) + parseFloat(unpaidPosSum.toFixed(2)),
      });
    };
    getPieChartData();
  }, [companyId, budgetId]);
  //Corien budget calculations version

  console.log("budget",budget)
  const calculateChartData = () => {
    if (!budget) return;
    return [
      { name: 'Allocated Unpaid', value: budget.budgetValue - budget.totalPOApprovedAmount },
      { name: 'Allocated Paid', value: budget.totalPOApprovedAmount },
    ];
  };
  useEffect(() => {
    budgetServiceService.getBudgetById(budgetId, companyId).then((res) => {
      setMainBudget(res.data.data);
      setLatestVersion(
        !!res.data.data?.versions.length ? res.data.data?.versions[0].version + 1 : '1',
      );
      setBudget(res.data.data);
    });
  }, []);

  const getGlItems = async () => {
    budgetServiceService.getBugetGLItems(budgetId, companyId).then((response) => {
      const formattedGLs = response?.data?.data?.glLineItems;
      setGlItems(formattedGLs);
    });
  };

  const createVersionOptions = () => {
    if (!mainBudget) return [];
    return [
      { label: `Version ${latestVersion}`, value: budgetId },
      ...mainBudget?.versions?.map((version) => ({
        label: `Version ${version.version}`,
        value: version.id,
      })),
    ];
  };

  if (!mainBudget) return <></>;

  return (
    <>
      <Text weight={600} type="h2" className="main-heading">
        {budget?.name}
      </Text>
      <Box $mobExtend $asHolder $noPadding>
        <div className={`${generalStyles.fieldsTwo} ${styles.budgetAdd}`}>
          <div className={styles.savedBudgetData}>
            <div className={styles.savedField}>
              <Text weight={600}>Currency</Text>
              <Text type="subtitle">{budget?.currency}</Text>
            </div>
            <div className={styles.savedField}>
              <Text weight={600}>Start Date</Text>
              <Text type="subtitle">{moment(budget?.startDate).format('DD/MM/YYYY')}</Text>
            </div>
            <div className={styles.savedField}>
              <Text weight={600}>End Date</Text>
              <Text type="subtitle">{moment(budget?.endDate).format('DD/MM/YYYY')}</Text>
            </div>
            <div className={styles.savedField}>
              <Text weight={600}>Budget Amount</Text>
              <Text type="subtitle">{`${budget?.currency} ${budget?.budgetValue}`}</Text>
            </div>
            <div className={styles.savedField}>
              <Text weight={600}>Version</Text>
              <Select
                defaultValue={{ label: `Version ${latestVersion}`, value: budgetId }}
                className={`react-select-container ${styles.budgetVersion}`}
                classNamePrefix="react-select"
                isSearchable={false}
                onChange={(e) => {
                  budgetServiceService
                    .getBudgetById(e.value, companyId)
                    .then((res) => setBudget(res.data.data));
                }}
                placeholder="Select Version"
                options={createVersionOptions()}
              />
            </div>
          </div>
          {budget && (
            // <PieChartComponent
            //   totalAmount={budget.totalPOApprovedAmount}
            //   currency={budget?.currency}
            //   piechartData={calculateChartData()}
            // />
            <PieChartComponent
              totalAmount={chartData.totalAmount}
              piechartData={chartData.pieChart}
              currency={budget?.currency}
            />
          )}
        </div>
        <div className={styles.glTable}>
          <BudgetDetailsGlList
            companyId={companyId}
            getGlItems={getGlItems}
            glItems={glItems}
            budget={budget}
          />
        </div>
        <div className={styles.approvedBudgetNotes}>
          <TabsSlider className={generalStyles.tabs}>
            <span onClick={() => setTab('notes')}>Notes</span>
            <span onClick={() => setTab('history')}>History</span>
            <span onClick={() => setTab('approval ')}>Approval </span>
          </TabsSlider>
          {tab === 'notes' && (
            <>
              {!budget?.notes?.length ? (
                <Text>No data to display</Text>
              ) : (
                <NotesTab data={budget?.notes} />
              )}
            </>
          )}
          {tab === 'history' && <HistoryTab data={budget?.history} />}
          {tab === 'approval ' && <ApprovalTab data={budget?.approval?.approvers} />}
        </div>
      </Box>

      <div className={generalStyles.pageButtons}>
        <Button.Main $primary $style="pink" type="button" onClick={() => navigate(-1)}>
          Back to Overview
        </Button.Main>
      </div>
    </>
  );
};

export default BudgetDetails;
