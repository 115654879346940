import React, {useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import styles from "../../../../styles/reports.module.css";
import Text from "../../../UI/Typography/Text";
import Select from "react-select";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import s2pmsService from "../../../../services/spmsService.service";
import moment from "moment";
import {CustomTooltip} from "./Tooltips";
import TableNoData from "../../../UI/General/TableNoData";

const ChartsDepartment = ({periods, companyId, currency}) => {
  const [chartData, setChartData] = useState(null)
  const {handleSubmit, control} = useForm({
    mode: 'onChange',
    defaultValues: {
      period: periods[0],
    }
  });

  const loadData = useCallback((departmentId, start , end = new Date()) => {
    if (companyId){
      let currentDate = moment();
      let previousMonthDate = currentDate.subtract(start, 'month');
      let search = {
        companyId: companyId,
        startDate: previousMonthDate,
        endDate: end
      }
      s2pmsService.reportsTopDepartment(search).then(r => {
        if (r.data.message === "Operation Successful"){
          setChartData(r.data.data)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },[companyId])

  const onSubmit = (data) => {
    loadData(data?.department?.value, data?.period?.value)
  }

  useEffect(() => {
    loadData(undefined, periods[0].value)
  }, [loadData, periods])

  return (
    <form className={styles.chartBlock}>
      <div className={styles.groupTitle}>
        <Text type={'h4'} weight={600}>Spend by Department</Text>
        <Text type={'body-2'} weight={500}>The Spend by Department insight will be the total approved PO spend for the specified period indicated by department</Text>
        <div className={styles.actions}>
          <Controller
            name={'period'}
            control={control}
            render={({ field}) => (
              <Select
                {...field}
                className={'react-select-container small'}
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select Period"
                options={periods}
                onChange={(selectedOption) => {
                  field.onChange(selectedOption);
                  handleSubmit(onSubmit)();
                }}
              />
            )}
          />
        </div>
      </div>
      {chartData?.monthlyDepartmentSpend && chartData?.monthlyDepartmentSpend?.length > 0 ? (
        <ResponsiveContainer width="100%" height={200}>
          <BarChart
            width={400}
            height={200}
            data={chartData?.monthlyDepartmentSpend}
            margin={{
              top: 0,
              right: 0,
              left: 10,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="departmentName"/>
            <YAxis label={{ value: `Value (${currency})`, angle: -90, position: 'insideLeft', style: {textAnchor: "middle"} }} />
            <Tooltip content={<CustomTooltip/>}/>
            <Bar dataKey="totalSpentAmount" fill="#39b7bc"/>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <TableNoData/>
      )}
    </form>
  );
};

export default ChartsDepartment;
